

.t-col {
  position: relative;
  width: 100%;
  box-sizing: border-box;
  min-height: 1px; // Prevent collapsing
  padding-right: 15px; // Half gutter
  padding-left: 15px; // Half gutter

}
