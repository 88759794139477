

.app__screenshots {
  margin-right: -25px;
  margin-bottom: 32px;
  gap: 16px;
  overflow: auto;
  white-space: nowrap;
  display: flex;
  padding-right: 25px;
  scrollbar-width: none;
  padding-top: 25px;

  &::-webkit-scrollbar {
    display: none;
  }
}

.app__screenshot {
  border-radius: 12px;
  background-size: cover;

  &--portrait {
    width: 210px;
    height: 372px;
  }

  &--landscape {
    width: 659px;
    height: 372px;
  }

  div {
    border-radius: 12px;
  }
}

@media screen and (max-width: 480px) {

  .app__screenshots {
    margin-right: -16px;
    padding-right: 16px;
  }
}


