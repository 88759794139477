

.app-result {
  display: flex;
  transition: 0.1s ease-in-out;
  gap: 16px;
  cursor: pointer;
  padding: 12px 16px;
  line-height: 1.4;
  color: inherit;
  background: transparent;
  border-radius: 8px;
  margin: 4px 4px -4px 6px;

  &:hover {
    background: var(--body-light-muted-color);
  }

  &__icon {
    border-radius: 16px;
    width: 42px;
    height: 42px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--app-icon-background);

    & img {
      width: 42px;
      height: 42px;
      border-radius: 8px;
    }

      & svg {
        color: #959a9e;
        width: 75%;
        height: 75%;
      }
  }

  &__name {
    font-weight: 500;
    font-size: 15px;
  }

  &__info {

  }

  &__short-description {
    font-weight: 400;
    font-size: 13px;
    color: var(--app-card-description);

    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
}

