

$line-height: 24px;

.app__description {
  max-width: 580px;
  font-weight: 400;
  line-height: $line-height;
  color: var(--body-text-color);

  &--loading {
    width: 100%;
    min-height: 115px;
    margin-top: 24px;
  }

  &-container {
    // Truncated description must contain 10 lines
    max-height: $line-height * 10 + 4;
    overflow: hidden;
    position: relative;

    &--blur::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 32px;
      background: linear-gradient(to top, var(--card-background), transparent);
      pointer-events: none; /* so the gradient doesn't block clickable elements */
    }

    &--expanded {
      max-height: none;
      margin-bottom: -16px;
    }
  }

  &-expander {
    margin-top: 12px;
    font-weight: 500;
    color: var(--blue-bright);
    cursor: pointer;
  }
}

