


.apps-list {

  &__card {
    margin-bottom: 20px;
  }

  &__filter-selector {
    display: flex;
    align-items: center;
    position: absolute;
    right: 8px;
  }

  &__not-found {
    height: 50vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@media screen and (max-width: 480px) {

  .t-col:first-child .apps-list__card-link .apps-list__card {
    border-top: 1px solid var(--card-border-color);
  }

  .apps-list {
    margin-top: 12px;
  }

  .apps-list__card {
    margin: 0 -16px;
    border-radius: 0;
    border-top: none;
    border-right: none;
    border-left: none;
  }
}

