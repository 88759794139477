

$search-box-border-size: 2px;

.search-container {
  position: relative;
  width: calc(100% - 4px);

  .search-focused {
    //background: var(--indexpage-search-background-color);
    //border-color: var(--app-search-field-focused-border-color)!important;
    background: var(--indexpage-search-background-color);
  }

  &__box {
    display: flex;
    align-items: center;
    width: 100%;
    height: calc(50px - $search-box-border-size * 2);
    color: inherit;
    position: relative;
    border-radius: .5rem;
    background: var(--indexpage-search-background-color);
    

    //background: var(--indexpage-search-background-color);
    border: $search-box-border-size solid var(--card-border-color);

    .search-icon {
      margin: 16px 9px 16px 16px;
      color: var(--app-search-icon);
    }

    .right-icon {
      cursor: pointer;
      width: 18px;
      height: 18px;
      margin-right: 16px;
    }

    .right-icon:empty {
      width: 0;
    }

    .search-input {
      flex: 1;
      border: none;
      background: transparent;
      color: inherit;
      outline: none;
      font-size: 16px;
      padding: 0;
      height: 100%;

      &::placeholder {
        color: #939394;
      }
      &::-webkit-input-placeholder {
        color: #939394;
      }
    }

    &--results-visible {
      border-radius: 12px 12px 0 0;
    }
  }
}

.search {
  &__results {
    border: 2px solid var(--card-border-color);
    border-top: none;
    position: absolute;
    background: var(--card-background);
    width: 100%;
    z-index: 10000;
    border-radius: 0 0 12px 12px;
  }

  &__results:empty {
    display: none;
  }
}

