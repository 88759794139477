

.t-selector {
  display: flex;
  line-height: 24px;
  width: fit-content;
  align-items: center;
  max-height: 44px;
  position: relative;
  padding: 3px;
  //background: var(--card-background);
  background: var(--body-light-muted-color);
  border-radius: .5rem;
  &__item {
    //height: calc(46px - 2px);
    border-radius: inherit;
    display: flex;
    align-items: center;
    justify-content: center;
    //border: 2px solid transparent;
    flex: 1;
    //padding: 16px 20px;
    padding: 0 20px;
    color: var(--selected-big-text);
    cursor: pointer;
    font-weight: 500;
    height: 100%;
    &-icon {
      margin-right: 8px;
    }

    &--selected {
      color: var(--selector-big-selected-text);
      //background: var(--selector-big-selected-background);
      //background: var(--card-background);
    }

    &-name {
      z-index: 10003;
    }
  }

  //&__border {
  //  position: absolute;
  //  bottom: 0;
  //  left: 0;
  //  height: calc(100% - 4px);
  //  //border: 2px solid var(--selector-big-selected-border);
  //  border: 2px solid transparent;
  //  transition: all 0.3s ease-in-out;
  //  border-radius: 17px;
  //}

  &__selected-background {
    position: absolute;
    bottom: 3px;
    height: calc(100% - 6px);
    transition: all 0.3s ease-in-out;
    border-radius: .4rem;
    background: var(--selector-active);
    z-index: 10002;
    width: calc(100% + 3px);
  }
}


@media screen and (max-width: 991px) {
  .t-selector {
    padding: 0;
    border-radius: .5rem;
    height: 100%;
    &__selected-background {
      border-radius: .4rem;
    }
  }
}

@media screen and (max-width: 480px) {

  .t-selector {
    padding: 0;
    border-radius: .5rem;
    

    &__item {
      padding: 8px 16px;
    }

    &__border {
      height: calc(100% - 2px);
      bottom: -1px;
    }


  }
}

