

.t-breadcrumbs {
  display: flex;
  gap: 8px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;

  &__item {
    display: flex;
    gap: 8px;
    align-items: center;
    color: var(--breadcrumbs-text-color);

    a, span {
      color: var(--breadcrumbs-text-color);
      cursor: pointer;
      text-decoration: none;
      -webkit-transition: all .2s;
      -o-transition: all .2s;
      transition: all .2s;
    }

    a:hover {
      color: var(--body-text-color);
    }

    svg {
      color: var(--breadcrumbs-text-color);
    }

    &--selected span {
      color: var(--body-text-color);
      cursor: default;
    }
  }
}

