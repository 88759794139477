

.apps-list {

  &__navigation {
    display: flex;
    justify-content: space-between;
    margin: 20px 0;
  }

  &__nav {
    display: flex;
    gap: 8px;
    flex-wrap: wrap;
    overflow: hidden;
    max-height: 40px;
    transition-property: max-height;
    transition-duration: 0.3s;
    transition-timing-function: ease-in-out;

    &--open {
      max-height: 500px;
    }
  }
}

@media screen and (max-width: 480px) {

  .apps-list {
    margin-top: 0;

    &__navigation {
      margin: 12px 0 12px 0;
    }
  }
}

