
.ui-looong {
    white-space: nowrap;
    font-feature-settings: 'tnum';
    display: inline-flex;
    flex-direction: row;
    flex-wrap: nowrap;
    max-width: 100%;
    min-width: 0;
    &::before {
        flex-grow: 0;
        content: attr(data-loopa);
        min-width: 0;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    &::after {
        flex-shrink: 0;
        content: attr(data-poopa);
    }
}

.address-link {
    text-decoration: none;
    border-bottom: 1px solid transparent;
    margin-bottom: -1px;

    // direct disabled child of <router-link> or <a> = clickable
    &.clickable, a > & {
        color: var(--blue-bright);
        &:hover {
            border-bottom-color: currentColor;
            text-decoration: underline;
        }
    }

    &.muted {
        opacity: .65;
        pointer-events: none;
    }

    &:hover {
        text-decoration: none;
    }
}
