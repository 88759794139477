

.app-wrapper {
  padding-top: 16px;
}

.apps-similar {
  margin-top: 40px;

  &__title {
    text-transform: uppercase;
    font-size: 18px;
    color: #858585;
    font-weight: 500;
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 600px) {
  .apps-similar {
    .t-row {
      flex-direction: column;
      gap: 20px;
    }
  }
}
