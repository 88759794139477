

.left-menu {
  position: sticky;
  top: 72px;
}

.apps-navigation {
  display: flex;
  flex-direction: column;

  &__item {
    padding-left: 10px;
    display: flex;
    align-items: center;
    height: 48px;
    color: var(--apps-navigation-inactive-color);
    cursor: pointer;
    transition: 0.1s ease-in-out;
    font-weight: 500;

    &:hover {
      color: var(--apps-navigation-hover-color);
      text-decoration: none;
    }

    &--active {
      color: var(--apps-navigation-icon-active);

      & svg {
        color: var(--apps-navigation-icon-active);
      }
    }

    &--active:hover {
      color: var(--apps-navigation-icon-active);
    }

    .item__wrapper {
      display: flex;
      width: 100%;
      height: 100%;
      align-items: center;

      .item__name {
        margin-left: 16px;
        line-height: 24px;
      }
    }
  }
}

